<template>

  <b-row>

    <!-- Left -->
    <b-col
      cols="12"
      lg="9"
    >

      <b-alert
        :show="userData.role === 'agent'"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        This form is for HR/Admin only.
      </b-alert>

      <b-overlay
        :show="userData.role === 'agent'"
        spinner-type="false"
        opacity="0.25"
        blur
        rounded
      >

        <!-- Card -->
        <b-card>

          <b-row class="hr-form">

            <b-col
              cols="12"
            >

              <b-card-title>
                Personnel Record (Admin)
                <br>
                <small>(Form to be kept current at all times)</small>
              </b-card-title>

              <validation-observer ref="simpleRules">
                <b-form>

                  <h4 class="text-center mt-2 mb-2">
                    FOR HOME CARE ORGANIZATION USE ONLY
                  </h4>

                  <input
                    v-model="form.id"
                    type="hidden"
                  >

                  <input
                    v-model="form.cid"
                    type="hidden"
                  >

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Home Care Organization Number"
                        label-for="home-care-number"
                      >
                        <label
                          class="sr-only"
                          for="home-care-number"
                        >
                          * Home Care Organization Number
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Home Care Organization Number"
                          rules="required"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              US (+1)
                            </b-input-group-prepend>
                            <b-form-input
                              id="home-care-number"
                              ref="homeCareNumber"
                              v-model="form.homeCareNumber"
                              v-mask="['(###) ###-####']"
                              :state="errors.length > 0 ? false : null"
                              type="tel"
                              placeholder="1234 567 8900"
                              title="Home Care Organization Number"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Employee's PER ID"
                        label-for="emp-per-id"
                      >
                        <label
                          class="sr-only"
                          for="emp-per-id"
                        >
                          * Employee's PER ID
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Employee's PER ID"
                          rules="required"
                        >
                          <b-form-input
                            id="emp-per-id"
                            ref="empPerId"
                            v-model="form.empPerId"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Employee's PER ID"
                            title="Employee's PER ID"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date of Employment"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date of Employment"
                          label-for="date-employment"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date-employment"
                          >
                            * Date of Employment
                          </label>
                          <flat-pickr
                            id="date-employment"
                            v-model="dateEmployment"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date of Separation"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date of Separation"
                          label-for="date-separation"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date-separation"
                          >
                            * Date of Separation
                          </label>
                          <flat-pickr
                            id="date-separation"
                            ref="dateSeparation"
                            v-model="form.dateSeparation"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Date of Separation"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <h4 class="text-center mt-2 mb-2">
                    PERSONAL
                  </h4>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Last Name"
                        label-for="last-name"
                      >
                        <label
                          class="sr-only"
                          for="last-name"
                        >
                          * Last Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Last Name"
                          rules="required"
                        >
                          <b-form-input
                            id="last-name"
                            v-model="lastName"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Last Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* First Name"
                        label-for="first-name"
                      >
                        <label
                          class="sr-only"
                          for="first-name"
                        >
                          * First Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="First Name"
                          rules="required"
                        >
                          <b-form-input
                            id="first-name"
                            v-model="firstName"
                            :state="errors.length > 0 ? false : null"
                            placeholder="First Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Middle Name"
                        label-for="middle-name"
                      >
                        <label
                          class="sr-only"
                          for="middle-name"
                        >
                          Middle Name
                        </label>
                        <b-form-input
                          id="middle-name"
                          v-model="middleName"
                          placeholder="Middle Name"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Area Code/Telephone"
                        label-for="tel"
                      >
                        <label
                          class="sr-only"
                          for="tel"
                        >
                          * Area Code/Telephone
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Area Code/Telephone"
                          rules="required"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              US (+1)
                            </b-input-group-prepend>
                            <b-form-input
                              id="tel"
                              v-model="telephone"
                              v-mask="['(###) ###-####']"
                              :state="errors.length > 0 ? false : null"
                              type="tel"
                              placeholder="1234 567 8900"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Address"
                        label-for="address"
                      >
                        <label
                          class="sr-only"
                          for="address"
                        >
                          * Address
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Address"
                          rules="required"
                        >
                          <b-form-input
                            id="address"
                            v-model="address"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Address"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date of Birth"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date of Birth"
                          label-for="date-birth"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date-birth"
                          >
                            * Date of Birth
                          </label>
                          <flat-pickr
                            id="date-birth"
                            v-model="dateBirth"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="SSN: (Voluntary for ID only)"
                        label-for="ssn"
                      >
                        <label
                          class="sr-only"
                          for="ssn"
                        >
                          SSN: (Voluntary for ID only)
                        </label>
                        <b-form-input
                          id="ssn"
                          v-model="ssn"
                          v-mask="['###-##-####']"
                          placeholder="Social Security Number"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date of Last TB Test"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date of Last TB Test"
                          label-for="date-last-tb-test"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date-last-tb-test"
                          >
                            * Date of Last TB Test
                          </label>
                          <flat-pickr
                            id="date-last-tb-test"
                            ref="dateLastTBTest"
                            v-model="form.dateLastTBTest"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Date of Last TB Test"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Results of Last TB Test"
                        label-for="results-last-tb-test"
                      >
                        <label
                          class="sr-only"
                          for="results-last-tb-test"
                        >
                          * Results of Last TB Test
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Results of Last TB Test"
                          rules="required"
                        >
                          <b-form-input
                            id="results-last-tb-test"
                            ref="resultsLastTBTest"
                            v-model="form.resultsLastTBTest"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Results of Last TB Test"
                            title="Results of Last TB Test"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="Additional TB Test Dates"
                        label-for="add-tb-test-dates"
                      >
                        <label
                          class="sr-only"
                          for="add-tb-test-dates"
                        >
                          Additional TB Test Dates
                        </label>
                        <b-form-input
                          id="add-tb-test-dates"
                          ref="addTBTestDates"
                          v-model="form.addTBTestDates"
                          v-mask="['####-##-## , ####-##-## , ####-##-## , ####-##-## , ####-##-##']"
                          placeholder="yyyy-mm-dd , yyyy-mm-dd , yyyy-mm-dd , yyyy-mm-dd , yyyy-mm-dd"
                          title="Additional TB Test Dates"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="8"
                    >
                      <b-form-group
                        label="Please include test results"
                        label-for="tbtest-res"
                      >
                        <label
                          class="sr-only"
                          for="tbtest-res"
                        >
                          Please include test results
                        </label>

                        <b-form-file
                          ref="attachedTbTestRes"
                          v-model="form.attachedTbTestRes"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                          type="file"
                          accept="image/*"
                          multiple
                          capture
                          title="TB Test Results"
                        >
                          <template
                            slot="file-name"
                            slot-scope="{ names }"
                          >
                            <b-badge variant="primary">
                              {{ names[0] }}
                            </b-badge>
                            <b-badge
                              v-if="names.length > 1"
                              variant="dark"
                              class="ml-1"
                            >
                              + {{ names.length - 1 }} More files
                            </b-badge>
                          </template>
                        </b-form-file>
                      </b-form-group>

                      <b-list-group
                        v-if="attachedTbTestResFile"
                        class="mb-1"
                      >

                        <b-list-group-item
                          v-for="(item, index) in attachedTbTestResFile"
                          :key="item"
                          class="d-flex justify-content-between text-truncate"
                        >

                          <span class="d-inline-block text-truncate mr-1">
                            <b-link
                              :href="`${urlUpload}${id}/${item}`"
                              target="_blank"
                              class="font-weight-bold pointer-events-auto"
                            >
                              {{ item }}
                            </b-link>
                          </span>

                          <span class="d-inline-block">
                            <feather-icon
                              :id="`row-${index}-tbtest-res-preview-icon`"
                              icon="EyeIcon"
                              size="16"
                              class="mr-1 pointer-events-auto"
                              role="button"
                              @click="previewFormFile({ id: id, item: item })"
                            />
                            <b-tooltip
                              title="Preview"
                              :target="`row-${index}-tbtest-res-preview-icon`"
                            />

                            <feather-icon
                              :id="`row-${index}-tbtest-res-delete-icon`"
                              icon="TrashIcon"
                              size="16"
                              role="button"
                              @click="deleteFormFile({ id: id, attachedTbTestRes: item })"
                            />
                            <b-tooltip
                              title="Delete"
                              :target="`row-${index}-tbtest-res-delete-icon`"
                            />
                          </span>
                        </b-list-group-item>
                      </b-list-group>

                    </b-col>
                  </b-row>

                  <b-row
                    align-v="center"
                  >
                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* Have you ever been Employed under a Different Name?"
                        label-for="diff-name"
                      >
                        <label
                          class="sr-only"
                          for="diff-name"
                        >
                          * Have you ever been Employed under a Different Name?
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Different Name"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="diffName"
                            v-model="form.diffName"
                            name="diffName"
                            title="Different Name"
                          >
                            <b-form-radio
                              v-for="option in radioYN"
                              :key="option.text"
                              :value="option.text"
                              :state="errors.length > 0 ? false : null"
                            >
                              {{ option.text }}
                            </b-form-radio>
                          </b-form-radio-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="If yes, please list all name used"
                        label-for="all-diff-name"
                      >
                        <label
                          class="sr-only"
                          for="all-diff-name"
                        >
                          If yes, please list all name used
                        </label>
                        <b-form-textarea
                          id="all-diff-name"
                          ref="allDiffName"
                          v-model="form.allDiffName"
                          placeholder="All Name Used"
                          rows="1"
                          max-rows="2"
                          title="All Name Used"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row
                    align-v="center"
                  >
                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* Do you possess a Valid California Driver's License?"
                        label-for="driver-license"
                      >
                        <label
                          class="sr-only"
                          for="driver-license"
                        >
                          * Do you possess a Valid California Driver's License?
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Driver's License"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="driverLicense"
                            v-model="form.driverLicense"
                            name="driverLicense"
                            title="Driver's License"
                          >
                            <b-form-radio
                              v-for="option in radioYN"
                              :key="option.text"
                              :value="option.text"
                              :state="errors.length > 0 ? false : null"
                            >
                              {{ option.text }}
                            </b-form-radio>
                          </b-form-radio-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="CDL Number"
                        label-for="cdl-number"
                      >
                        <label
                          class="sr-only"
                          for="cdl-number"
                        >
                          CDL Number
                        </label>
                        <b-form-input
                          id="cdl-number"
                          ref="cdlNumber"
                          v-model="form.cdlNumber"
                          placeholder="CDL Number"
                          title="CDL Number"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <h4 class="text-center mt-2 mb-2">
                    POSITION INFORMATION
                  </h4>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Title of Position"
                        label-for="position"
                      >
                        <label
                          class="sr-only"
                          for="position"
                        >
                          * Title of Position
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Title of Position"
                          rules="required"
                        >
                          <b-form-input
                            id="position"
                            v-model="position"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Title of Position"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* Notes"
                        label-for="notes"
                      >
                        <label
                          class="sr-only"
                          for="notes"
                        >
                          * Notes
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Notes"
                          rules="required"
                        >
                          <b-form-textarea
                            id="notes"
                            ref="notes"
                            v-model="form.notes"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Notes"
                            rows="3"
                            max-rows="6"
                            title="Notes"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <p class="text-center mt-2 mb-2">
                    <strong>I hereby certify under penalty of perjury that I am 18 years of age or older and that the above statements are true and correct. I give my permission for any necessary verification.</strong>
                  </p>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Employee Signature"
                        label-for="emp-signature"
                      >
                        <label
                          class="sr-only"
                          for="emp-signature"
                        >
                          * Employee Signature
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Employee Signature"
                          rules="required"
                        >
                          <vue-signature-pad
                            ref="empSignature"
                            v-model="form.empSignature"
                            :state="errors.length > 0 ? false : null"
                            width="100%"
                            height="150px"
                            :options="{ onBegin, onEnd }"
                            class="form-control"
                            :class="{ 'is-invalid' : errors.length > 0 && form.empSignature === '' }"
                            title="Employee Signature"
                          />

                          <b-row>

                            <b-col>
                              <small
                                v-if="form.empSignature === ''"
                                class="text-danger"
                              >
                                {{ errors[0] }}
                              </small>
                            </b-col>

                            <b-col
                              class="text-right mt-1"
                            >
                              <!-- Button Undo -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-dark"
                                class="mr-1"
                                @click="undoSignature"
                              >
                                Undo
                              </b-button>

                              <!-- Button Clear -->
                              <b-button
                                variant="outline-danger"
                                @click="clearSignature"
                              >
                                Clear
                              </b-button>
                            </b-col>
                          </b-row>

                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date"
                          label-for="date-signed"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date-signed"
                          >
                            * Date
                          </label>
                          <flat-pickr
                            id="date-signed"
                            ref="dateSigned"
                            v-model="form.dateSigned"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <!-- Button Submit -->
                  <b-button
                    variant="primary"
                    type="submit"
                    class="mr-1 d-none"
                    @click.prevent="validationForm"
                  >
                    Submit
                  </b-button>

                </b-form>
              </validation-observer>

            </b-col>
          </b-row>

        </b-card>

      </b-overlay>
    </b-col>

    <!-- Right -->
    <b-col
      cols="12"
      lg="3"
    >

      <!-- Action Buttons -->
      <b-card>

        <!-- Button Preview -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="gradient-main"
          class="mb-75 btn-print-preview"
          block
          :disabled="userData.role === 'agent' ? true : isNotSaved"
          @click="$store.dispatch('app-hr/preview')"
        >
          Print Preview
        </b-button>

        <!-- Button Save -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          block
          :disabled="userData.role === 'agent' ? true : isNotSaved"
          @click.prevent="validationForm()"
        >
          Save
        </b-button>

        <!-- Button Cancel -->
        <b-button
          v-if="action !== 'preview'"
          variant="outline-dark"
          class="mb-75"
          :to="{ name: (userData.role === 'agent' ? 'caregiverLanding' : 'hr') }"
          block
        >
          Cancel
        </b-button>

        <div class="d-flex">
          <!-- Button Previous -->
          <b-button
            variant="outline-dark"
            class="flex-fill mr-25"
            :to="{ name: 'apps-hr-form13', params: { action: action }, query: { id: id } }"
          >
            Previous
          </b-button>

          <!-- Button Next -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="flex-fill ml-25"
            :to="{ name: 'apps-hr-form14b', params: { action: action }, query: { id: id } }"
          >
            Next
          </b-button>
        </div>

        <!-- Spacer -->
        <hr class="mb-50">

        <label for="forms">Form Selection</label>
        <v-select
          id="forms"
          v-model="forms"
          :reduce="(option) => option.name"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formsOptions"
          :clearable="false"
          :disabled="!id"
          class="hr-forms-select"
          @input="$router.push({ name: forms, params: { action: action }, query: { id: id } })"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BOverlay, BAlert, BCard, BCardTitle, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroupPrepend, BInputGroup, BFormRadioGroup, BFormRadio, VBToggle, BFormTextarea, BFormFile, BTooltip, BListGroup, BListGroupItem, BLink, BBadge,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import { required } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { VueSignaturePad } from 'vue-signature-pad'
import { mask } from 'vue-the-mask'
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import { mapState } from 'vuex'

import { getUserData } from '@/auth/utils'

import hrStoreModule from '../hrStoreModule'

export default {
  components: {
    BOverlay,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BButton,
    BFormRadioGroup,
    BFormRadio,
    BFormTextarea,
    BFormFile,
    BTooltip,
    BListGroup,
    BListGroupItem,
    BLink,
    BBadge,

    vSelect,
    flatPickr,
    VueSignaturePad,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    mask,
  },
  setup() {
    const HR_APP_STORE_MODULE_NAME = 'app-hr'
    // Register module
    if (!store.hasModule(HR_APP_STORE_MODULE_NAME)) store.registerModule(HR_APP_STORE_MODULE_NAME, hrStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HR_APP_STORE_MODULE_NAME)) store.unregisterModule(HR_APP_STORE_MODULE_NAME)
    })
    const form = ref({})

    const empSignature = ref(null)
    const attachedTbTestResFile = ref(null)

    const dateEmployment = ref('')
    const lastName = ref('')
    const firstName = ref('')
    const middleName = ref('')
    const telephone = ref('')
    const address = ref('')
    const dateBirth = ref('')
    const ssn = ref('')
    const position = ref('')
    const input = ref(true)

    if (router.currentRoute.query.id) {
      store.dispatch('app-hr/fetchForm', { id: router.currentRoute.query.id })
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data
          form.value = {}
          form.value.cid = router.currentRoute.query.id
          dateEmployment.value = jsonObjectLiterals.data.hireDate
          lastName.value = jsonObjectLiterals.data.lastName
          firstName.value = jsonObjectLiterals.data.firstName
          middleName.value = jsonObjectLiterals.data.middleName
          telephone.value = jsonObjectLiterals.data.phoneNumber
          // eslint-disable-next-line
          address.value = jsonObjectLiterals.data.street + ' ' + jsonObjectLiterals.data.aptunit + ' ' + jsonObjectLiterals.data.cityTown + ' ' + jsonObjectLiterals.data.state + ' ' + jsonObjectLiterals.data.zipCode
          dateBirth.value = jsonObjectLiterals.data.dateOfBirth
          ssn.value = jsonObjectLiterals.data.ssn
          position.value = jsonObjectLiterals.data.position

          if (router.currentRoute.params.action === 'edit' || (router.currentRoute.params.action === 'add' && router.currentRoute.query.id)) {
            store.dispatch('app-hr/fetchForm14a', { cid: router.currentRoute.query.id })
              .then(response1 => {
                // eslint-disable-next-line
                const jsonObjectLiterals = response1.data.response.data
                if (jsonObjectLiterals.data.cid) {
                  form.value = jsonObjectLiterals.data
                }
                if (jsonObjectLiterals.data.empSignature) {
                  empSignature.value.fromDataURL(jsonObjectLiterals.data.empSignature)
                }

                attachedTbTestResFile.value = jsonObjectLiterals.data.attachedTbTestRes
              })
              .catch(error => {
                console.error(error)
              })
          }
        })
        .catch(error => {
          console.error(error)
        })
    }

    const userData = ref(getUserData())

    const formsOptions = [
      { label: 'Basic Information', name: 'apps-hr-form' },
      { label: 'Employment Checklist', name: 'apps-hr-form1' },
      { label: 'Employment Application', name: 'apps-hr-form2' },
      { label: 'Employment Skills List', name: 'apps-hr-form3' },
      { label: 'Work Experiences Checklist', name: 'apps-hr-form4' },
      { label: 'Employment Eligibility Verification', name: 'apps-hr-form5' },
      { label: 'Form W-4', name: 'apps-hr-form6' },
      { label: 'Non-Compete and Non-Solicitation Agreement', name: 'apps-hr-form7' },
      { label: 'Employment Verification Fax', name: 'apps-hr-form8' },
      { label: 'Ref Inquiry Form', name: 'apps-hr-form9' },
      { label: 'Request for Live Scan Service - Community Care Licensing', name: 'apps-hr-form10' },
      { label: 'New Hire Orientation Acknowledgment Form', name: 'apps-hr-form11' },
      { label: 'Employee Tuberculin Skin Test (TST) and Evaluation', name: 'apps-hr-form12' },
      { label: 'Home Care Organization Inspection Checklist', name: 'apps-hr-form13' },
      { label: 'Personnel Record (Admin)', name: 'apps-hr-form14a' },
      { label: 'Personnel Record', name: 'apps-hr-form14b' },
      { label: 'Applicant for Home Care Aide Registration', name: 'apps-hr-form14c' },
      { label: 'Criminal Record Statement', name: 'apps-hr-form15' },
      { label: 'Statement Acknowledging Requirement to Report Suspected Abuse of Dependent Adults and Elders', name: 'apps-hr-form16' },
      { label: 'Registered Home Care Aide Training Log A', name: 'apps-hr-form17a' },
      { label: 'Registered Home Care Aide Training Log B', name: 'apps-hr-form17b' },
      { label: 'Workplace Safety', name: 'apps-hr-form18' },
      { label: 'Current Knowledge', name: 'apps-hr-form19' },
      { label: 'Fact Sheet (Home Care Services - April 2017)', name: 'apps-hr-form20' },
      { label: 'Acknowledgement Form - Fact Sheet (Home Care Services)', name: 'apps-hr-form21' },
      { label: 'Training Completion Certificate', name: 'apps-hr-form22' },
    ]

    const flatPickrConfig = {
      dateFormat: 'Y-m-d',
    }

    const vueSignaturePadOptions = {
      penColor: 'rgb(0, 0, 0)',
    }

    return {
      form,

      empSignature,
      attachedTbTestResFile,

      dateEmployment,
      lastName,
      firstName,
      middleName,
      telephone,
      address,
      dateBirth,
      ssn,
      position,
      input,

      userData,

      formsOptions,
      flatPickrConfig,
      vueSignaturePadOptions,
    }
  },
  data() {
    return {
      // data
      id: this.$route.query.id,
      action: this.$route.params.action,
      urlUpload: this.$urlUpload,

      isNotSaved: false,

      forms: this.$route.name,

      required,
      // options
      radioYN: [
        {
          id: 1,
          text: 'Yes',
        },
        {
          id: 2,
          text: 'No',
        },
      ],
    }
  },
  computed: {
    ...mapState('app-hr', ['uploadPercentage']),
  },
  methods: {
    clearSignature() {
      this.$refs.empSignature.clearSignature()
    },
    undoSignature() {
      this.$refs.empSignature.undoSignature()
    },
    onBegin() {
      // Do something
    },
    onEnd() {
      const { isEmpty, data } = this.$refs.empSignature.saveSignature()
      this.form.empSignature = !isEmpty ? data : ''
    },
    previewFormFile(params) {
      Swal.fire({
        text: params.item,
        padding: '1em 3em',
        // eslint-disable-next-line
        imageUrl: this.$urlUpload + params.id + '/' + params.item,
        imageAlt: params.item,
        showConfirmButton: false,
        showCloseButton: true,
        returnFocus: false,
      })
    },
    deleteFormFile(params) {
      const self = this

      Swal.fire({
        icon: 'warning',
        title: 'Are you sure?',
        // eslint-disable-next-line
        text: 'Do you want to delete ' + Object.values(params)[1] + '?',
        confirmButtonText: 'Proceed',
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn btn-secondary',
          cancelButton: 'btn btn-outline-dark mr-1',
        },
        buttonsStyling: false,
        returnFocus: false,
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('app-hr/deleteForm14aFile', params)
            .then(response => {
              // eslint-disable-next-line
              const jsonObjectLiterals = response.data.response.data

              // console.log(jsonObjectLiterals)

              // update object file
              const key = Object.keys(params)[1]
              self.form[key] = jsonObjectLiterals.data[key]
              self[`${key}File`] = jsonObjectLiterals.data[key]

              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                // eslint-disable-next-line
                text: 'Deleted succesfully.',
                showConfirmButton: false,
                timer: 1500,
              })
            })
            .catch(error => {
              console.error(error)

              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                showConfirmButton: false,
                timer: 1500,
              })
            })
        }
      })
    },
    validationForm() {
      const self = this

      /* const { isEmpty, data } = this.$refs.empSignature.saveSignature()
      this.form.empSignature = !isEmpty ? data : '' */

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let msg = 'Do you want to save the changes?'
          if (this.action === 'add') msg = 'Do you want to add these data?'
          Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: msg,
            confirmButtonText: 'Proceed',
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.isNotSaved = true

              store
                .dispatch(this.action === 'add' ? 'app-hr/addForm14a' : 'app-hr/updateForm14a', this.form)
                .then(response => {
                  // eslint-disable-next-line
                  const jsonObjectLiterals = response.data.response.data

                  this.form.id = jsonObjectLiterals.id

                  Swal.fire({
                    icon: 'success',
                    title: 'Saved!',
                    text: 'Saved successfully.',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .catch(error => {
                  console.error(error)

                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .finally(() => {
                  self.isNotSaved = false
                })
            }
          })
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'Are you sure?',
            // eslint-disable-next-line
            text: 'Please fill-up all of the required fields.',
            confirmButtonText: 'OK',
            showCancelButton: false,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
.min-width-300px
{
  min-width: 300px;
}
.min-width-140px
{
  min-width: 140px;
}
.mt-1rem {
  margin-top: 1rem;
}
</style>

<style lang="scss" scoped>
.hr-forms-select {
  width: 100%;
  min-width: 182px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 135px;
  }
}

@media (max-width: 1199.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 112px;
    }
  }
}

@media (max-width: 991.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 598px;
    }
  }
}

@media (max-width: 767.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 406px;
    }
  }
}

@media (max-width: 575.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 352px;
    }
  }
}
</style>
